import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Curso from "../components/curso";
import Tree from "../images/cursos/Tree.webp";
import Nature from "../Assets/nature.webp";

const Zaratustra = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <Layout>
      <Curso
        title="Así Habló Zaratustra, Parte 1"
        phrase="Análisis literario, psicológico y filosófico."
        time="8 Horas"
        class="6 Sesiones"
        students="3 - 6 Estudiantes"
        firstImg={Tree}
        secondImg={Nature}
        description="En este curso analizaremos fragmentos de la primera parte del libro Así Habló Zaratustra de F. Nietzsche. El análisis tendrá un enfoque principalmente filosófico-psicológico: discutiremos qué impacto personal tienen las ideas del libro para que sean así herramientas de crecimiento y transformación personal."
        list={[
          "Breve biografía de Nietzsche, enfocada en comprender el origen y sentido de las ideas planteadas en la primera parte e Así Habló Zaratustra.",
          "Sistema simbólico e ideas principales a través del análisis del prólogo.",
          "Las tres transformaciones del espíritu y el concepto de virtud.",
          "Crítica a instituciones religiosas y académicas.",
          "Crítica a la vida del “rebaño”.",
          "La voluntad de poder y superación de un@ mism@.",
          "Conclusiones",
        ]}
        duracion="6 sesiones de entre 80 y 90 min."
        cupo="mínimo 3 personas, máximo 6 personas."
        precio=" $2,000 MXN / $120 USD aprox."
        top="10%"
        imgWidth="16%"
        right="5%"
        topMob="18%"
        rightMob="1%"
        imgWidthMob="35%"
      />
    </Layout>
  ) : null;
};

export default Zaratustra;
